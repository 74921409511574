import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Task from "./pages/Task";
import Login from "./pages/Login";
import Leads from "./pages/Leads";
import Wallet from "./pages/Wallet";
import Assets from "./pages/Assets";
import Result from "./pages/Result";
import Finance from "./pages/Finance";
import Balance from "./pages/Balance";
import Projects from "./pages/Projects";
import Employee from "./pages/Employee";
import NotFound from "./pages/NotFound";
import Telegram from "./pages/Telegram";
import Rotation from "./pages/Rotation";
import ResetLink from "./pages/ResetLink";
import Customers from "./pages/Customers";
import IncomeTax from "./pages/IncomeTax";
import QqcMonthly from "./pages/QqcMonthly";
import { getEnv } from "./utils/environment";
import Transaction from "./pages/Transaction";
import QqcQuarterly from "./pages/QqcQuarterly";
import UploadResult from "./pages/UploadResult";
import ConfirmEmail from "./pages/ConfirmEmail";
import SettingsUser from "./pages/SettingsUser";
import TaskProjects from "./pages/TaskProjects";
import UploadBalance from "./pages/UploadBalance";
import ResetPassword from "./pages/ResetPassword";
import ManthlyResult from "./pages/ManthlyResult";
import UploadRotation from "./pages/UploadRotation";
import ManthlyBalance from "./pages/ManthlyBalance";
import EmployeeDetail from "./pages/EmployeeDetail";
import ManthlyFinance from "./pages/ManthlyFinance";
import SettingsFinance from "./pages/SettingsFinance";
import UploadIncomeTax from "./pages/UploadIncomeTax";
import IncomeQuarterly from "./pages/IncomeQuarterly";
import ManthlyRotation from "./pages/ManthlyRotation";
import UploadQqcMonthly from "./pages/UploadQqcMonthly";
import ManthlyIncomeTax from "./pages/ManthlyIncomeTax";
import InvitationSignUp from "./pages/InvitationSignUp";
import TaskProjectBoard from "./pages/TaskProjectBoard";
import TaskProjectBoards from "./pages/TaskProjectBoards";
import ManthlyQqcMonthly from "./pages/ManthlyQqcMonthly";
import SettingsMarketing from "./pages/SettingsMarketing";
import StatisticsFinance from "./pages/StatisticsFinance";
import TaskProjectBacklog from "./pages/TaskProjectBacklog";
import UploadQqcQuarterly from "./pages/UploadQqcQuarterly";
import ManthlyUploadResult from "./pages/ManthlyUploadResult";
import ManthlyQqcQuarterly from "./pages/ManthlyQqcQuarterly";
import ProtectedRoute from "./components/common/ProtectedRoute";
import ManthlyUploadBalance from "./pages/ManthlyUploadBalance";
import ResetPasswordMessage from "./pages/ResetPasswordMessage";
import BaseContextWrapper from "./components/common/BaseContext";
import ManthlyUploadRotation from "./pages/ManthlyUploadRotation";
import UploadIncomeQuarterly from "./pages/UploadIncomeQuarterly";
import DevelopersLeaderBoard from "./pages/DevelopersLeaderBoard";
import TaskProjectBoardTable from "./pages/TaskProjectBoardTable";
import ManthlyUploadIncomeTax from "./pages/ManthlyUploadIncomeTax";
import ManthlyIncomeQuarterly from "./pages/ManthlyIncomeQuarterly";
import ManthlyUploadQqcMonthly from "./pages/ManthlyUploadQqcMonthly";
import EmailConfirmationMessage from "./pages/EmailConfirmationMessage";
import ManthlyUploadQqcQuarterly from "./pages/ManthlyUploadQqcQuarterly";
import ManthlyUploadIncomeQuarterly from "./pages/ManthlyUploadIncomeQuarterly";
import SettingsDue from "./pages/SettingsDue";
import Due from "./pages/Due";
import UploadDue from "./pages/UploadDue";
import ManthlyDue from "./pages/ManthlyDue";
import ManthlyUploadDue from "./pages/ManthlyUploadDue";
import MonthlyCLientDetail from "./pages/monthly/Monthly";
import ZeroClientDetail from "./pages/zero/Zero";
import FinanceKeys from "./pages/FinanceKeys";
import FinanceType from "./pages/FinanceType";
import Salary from "./pages/Salary";

export default function App() {
  useEffect(() => {
    if (getEnv() === "production") return;
    document.title = `${getEnv().toUpperCase()} ${document.title}`;
    document.querySelector("link[rel='shortcut icon']").href = `/${getEnv()}.favicon.png`;
  }, []);

  return (
    <BrowserRouter>
      <BaseContextWrapper>
        <Switch>
          <Route path="/" component={Login} exact />
          <Route path="/reset-password/:key" component={ResetPassword} exact />
          <Route path="/reset-link" component={ResetLink} exact />
          <Route path="/confirm/:confirmationCode" component={ConfirmEmail} exact />
          <Route path="/email-confirmation-message" component={EmailConfirmationMessage} exact />
          <Route path="/reset-password-message" component={ResetPasswordMessage} exact />
          <Route path="/invitation/:code" component={InvitationSignUp} exact />
          <Route path="/task/projects/:projectId/all-boards/" component={TaskProjectBoards} exact />
          <Route path="/leaderboard" component={DevelopersLeaderBoard} exact />

          <ProtectedRoute path="/finance" component={Finance} exact />
          <ProtectedRoute path="/salary" component={Salary} exact />
          <ProtectedRoute path="/counterparty" component={Salary} exact />

          <ProtectedRoute path="/finance-type" component={FinanceType} exact />
          <ProtectedRoute path="/finance-keys" component={FinanceKeys} exact />
          <ProtectedRoute path="/finance/wallet" component={Wallet} exact />
          <ProtectedRoute path="/finance/asset" component={Assets} exact />
          <ProtectedRoute path="/finance/wallet/:walletId/transaction" component={Transaction} exact />

          <ProtectedRoute path="/statistics/finance" component={StatisticsFinance} exact />
          <ProtectedRoute path="/settings/user" component={SettingsUser} exact />
          <ProtectedRoute path="/settings/due" component={SettingsDue} exact />
          <ProtectedRoute path="/settings/finance" component={SettingsFinance} exact />
          <ProtectedRoute path="/settings/marketing" component={SettingsMarketing} exact />

          <ProtectedRoute path="/task/projects" component={TaskProjects} exact />
          <ProtectedRoute path="/task/board/:id" component={TaskProjectBoard} exact />
          <ProtectedRoute path="/task/table-board/:id" component={TaskProjectBoardTable} exact />
          <ProtectedRoute path="/task/projects/:id/backlog" component={TaskProjectBacklog} exact />

          <ProtectedRoute path="/staff/employee" component={Employee} exact />
          <ProtectedRoute path="/staff/employee/:employeeId" component={EmployeeDetail} exact />

          <ProtectedRoute path="/project/leads" component={Leads} exact />
          <ProtectedRoute path="/project/projects" component={Projects} exact />

          <ProtectedRoute path="/zero/rotation" component={Rotation} exact />
          <ProtectedRoute path="/zero/income-tax" component={IncomeTax} exact />
          <ProtectedRoute path="/zero/qqc-monthly" component={QqcMonthly} exact />
          <ProtectedRoute path="/zero/income-quarterly" component={IncomeQuarterly} exact />
          <ProtectedRoute path="/zero/balance" component={Balance} exact />
          <ProtectedRoute path="/zero/result" component={Result} exact />

          <ProtectedRoute path="/zero/income-tax-item/:incomeTaxId" component={UploadIncomeTax} exact />
          <ProtectedRoute path="/zero/income-quarterly-item/:incomeQuarterlyId" component={UploadIncomeQuarterly} exact />
          <ProtectedRoute path="/zero/rotation-item/:rotationId" component={UploadRotation} exact />
          <ProtectedRoute path="/zero/qqc-monthly-item/:qqcMonthlyId" component={UploadQqcMonthly} exact />
          <ProtectedRoute path="/zero/qqc-quarterly" component={QqcQuarterly} exact />
          <ProtectedRoute path="/zero/qqc-quarterly-item/:qqcQuarterlyId" component={UploadQqcQuarterly} exact />
          <ProtectedRoute path="/zero/:dueId" component={Due} exact />
          <ProtectedRoute path="/zero/:dueId/:dueDateId" component={UploadDue} exact />
          <ProtectedRoute path="/zero/balance-item/:balanceId" component={UploadBalance} exact />
          <ProtectedRoute path="/zero/result-item/:resultId" component={UploadResult} exact />

          <ProtectedRoute path="/clients" component={Finance} exact />
          <ProtectedRoute path="/clients/manthly/:clientId" component={MonthlyCLientDetail} exact />
          <ProtectedRoute path="/clients/zero/:clientId" component={ZeroClientDetail} exact />

          <ProtectedRoute path="/manthly/rotation" component={ManthlyRotation} exact />
          <ProtectedRoute path="/manthly/income-tax" component={ManthlyIncomeTax} exact />
          <ProtectedRoute path="/manthly/qqc-monthly" component={ManthlyQqcMonthly} exact />
          <ProtectedRoute path="/manthly/income-quarterly" component={ManthlyIncomeQuarterly} exact />
          <ProtectedRoute path="/manthly/result" component={ManthlyResult} exact />
          <ProtectedRoute path="/manthly/balance" component={ManthlyBalance} exact />

          <ProtectedRoute path="/manthly/clients" component={ManthlyFinance} exact />

          <ProtectedRoute path="/manthly/income-tax-item/:incomeTaxId" component={ManthlyUploadIncomeTax} exact />
          <ProtectedRoute path="/manthly/income-quarterly-item/:incomeQuarterlyId" component={ManthlyUploadIncomeQuarterly} exact />
          <ProtectedRoute path="/manthly/rotation-item/:rotationId" component={ManthlyUploadRotation} exact />
          <ProtectedRoute path="/manthly/qqc-monthly-item/:qqcMonthlyId" component={ManthlyUploadQqcMonthly} exact />
          <ProtectedRoute path="/manthly/qqc-quarterly" component={ManthlyQqcQuarterly} exact />
          <ProtectedRoute path="/manthly/qqc-quarterly-item/:qqcQuarterlyId" component={ManthlyUploadQqcQuarterly} exact />

          <ProtectedRoute path="/manthly/:dueId" component={ManthlyDue} exact />
          <ProtectedRoute path="/manthly/:dueId/:dueDateId" component={ManthlyUploadDue} exact />
          <ProtectedRoute path="/manthly/balance-item/:balanceId" component={ManthlyUploadBalance} exact />
          <ProtectedRoute path="/manthly/result-item/:resultId" component={ManthlyUploadResult} exact />

          <ProtectedRoute path="/telegram" component={Telegram} exact />
          <ProtectedRoute path="/project/customers" component={Customers} exact />
          <ProtectedRoute path="/task" component={Task} exact />
          <Route path="" component={NotFound} exact />
        </Switch>
      </BaseContextWrapper>
    </BrowserRouter>
  );
}
