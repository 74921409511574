import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import uniqBy from "lodash/uniqBy";
import { auth, signOut } from "../utils/auth";
import baseAxios from "../utils/request";

import { useMessage } from "./message";

export function usePostRequest(options = {}) {
  return useRequest({ method: "POST", ...options });
}

export function usePutRequest(options = {}) {
  return useRequest({ method: "PUT", ...options });
}

export function useGetRequest(options = {}) {
  return useRequest({ method: "GET", ...options });
}

export function useDeleteRequest(options = {}) {
  return useRequest({ method: "DELETE", ...options });
}

export function useRequest(options = {}) {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [showMessage] = useMessage();

  const history = useHistory();

  async function request(overrideOptions = {}, sync = false) {
    setLoading(true);

    try {
      const { data } = await baseAxios({ ...auth(), ...options, ...overrideOptions });
      if (!sync) setResponse(data);
      return { response: data, success: true };
    } catch (e) {
      setError(e.response);
      if (e.response === undefined) {
        showMessage("Проверьте интернет соединение", "is-danger");
      } else if (e.response.status >= 500) {
        showMessage("Ошибка сервера.", "is-danger");
      } else if (e.response.status === 401) {
        signOut(history);
      }

      return { error: e.response, success: false };
    } finally {
      if (!sync) setLoading(false);
    }
  }

  return { loading, request, error, response, setResponse, setError, setLoading };
}

export function useLoad(options, dependencies = [], load = true) {
  const request = useRequest({ method: "GET", ...options });

  useEffect(() => {
    if (load) {
      request.request();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return request;
}

export function useInfiniteScroll(options, dependencies = []) {
  const [page, setPage] = useState(1);
  const items = useGetRequest({ ...options, params: { ...options.params, page } });
  const [hasMore, setHasMore] = useState(false);
  const observer = useRef();
  const [firstStart, setFirstStart] = useState(false);

  useEffect(() => {
    loadItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (firstStart) {
      reload();
    }
    setFirstStart(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);

  async function loadItems() {
    const { response } = await items.request();
    const oldItems = items.response ? items.response.results : [];
    const newItems = response ? response.results : [];

    if (!response) return;

    items.setResponse({ count: response ? response.count : 0, results: uniqBy([...oldItems, ...newItems], "id") });
    setHasMore(oldItems.length + newItems.length !== response.count);
  }

  async function reload() {
    items.setResponse({ count: 0, results: [] });
    setPage(1);
    await items.request({ params: { ...options.params, page: 1 } });
  }

  const ref = useCallback(
    (node) => {
      if (items.loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(page + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, items.loading, page],
  );

  return {
    ref,
    ...items,
    hasMore,
    reload,
  };
}

export function useSortTableData(items) {
  const [sortConfig, setSortConfig] = useState(null);
  const sortedItems = useMemo(() => {
    const sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
}

const initConfig = {
  fileName: `export-${Date.now()}.xlsx`,
};

export function useXlsExport(config = initConfig) {
  const [data, setData] = useState();

  useEffect(() => {
    if (!data) {
      return;
    }

    const blob = new Blob([data], {
      type: "application/vnd.ms-excel",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.style = "display: none";

    document.body.append(link);

    link.href = url;
    link.download = config.fileName;
    link.click();
    window.URL.revokeObjectURL(url);
  }, [config.fileName, data]);

  return [data, setData];
}
