import React, { useState } from "react";
import Button from "../components/common/Button";
import { useQueryParams } from "../hooks/queryString";
import { useModal } from "../hooks/modal";
import { useLoad } from "../hooks/request";
import { QQC_MONTHLY_LIST } from "../urls";
import QqcMonthlyCreate from "../components/QqcMonthlyCreate";
import QqcMonthlyItem from "../components/QqcMonthlyItem";
import Layout from "../components/Layout";
import ZeroTabs from "../components/ZeroTabs";
import Table from "../components/common/Table";
import { currentLastMonth, getMonth } from "../utils/date";
import Select from "react-select";

export default function QqcMonthly() {
  const [month, setMonth] = useState(currentLastMonth);

  const params = useQueryParams();
  const qqcMonthly = useLoad({ url: QQC_MONTHLY_LIST, params }, [params]);
  const reportItem = qqcMonthly.response ? qqcMonthly.response.results : [];

  const [showModal, hideModal] = useModal(
    <QqcMonthlyCreate
      onSuccess={() => {
        hideModal();
        qqcMonthly.request();
      }}
      onCancel={() => hideModal()}
    />,
  );

  const filterData = reportItem.filter((item) => item.date.slice(0, 7) === month.slice(0, 7) || month === "все");
  const options = reportItem.map((item) => ({ value: item.date, label: getMonth(item.date) }));
  options.unshift({ value: "все", label: "Все" });
  const handleMonthChange = (selectedOption) => {
    setMonth(selectedOption.value);
  };

  return (
    <Layout>
      <div style={{ position: "sticky", top: 0, left: 0, width: "100%", zIndex: 10, backgroundColor: "white" }}>
        <ZeroTabs />
        <div className="columns mb-4 mr-3">
          <div className="column is-capitalized ml-4">
            <span className="is-size-4">ҚҚС Ойлик:</span>
          </div>
          <div className="column mt-3">
            <div className="columns is-pulled-right">
              <Button icon="ion-md-calendar" text="Янги ой қўшиш" onClick={showModal} className="button is-success is-outlined" />
            </div>
            <div className="columns is-pulled-right mr-5" style={{ minWidth: "200px" }}>
              <Select
                defaultValue={{ value: month, label: getMonth(month) }}
                options={options}
                onChange={handleMonthChange}
                placeholder="Ойни танланг"
                styles={{ control: (provided) => ({ ...provided, borderColor: "#d1d5db", borderRadius: "8px", padding: "2px", width: "200px", cursor: "pointer" }) }}
              />
            </div>
          </div>
        </div>
      </div>
      <Table
        loading={qqcMonthly.loading}
        items={filterData}
        totalCount={qqcMonthly.response ? qqcMonthly.response.count : 0}
        columns={{
          id: "№",
          name: "Ой номи",
          stir: "Мижозлар",
          directorStir: "Ҳисоботлар жўнатилиши",
          ssuploadRotation: "Солиқлар тўланиши",
          ad: "",
          uploadRotation: "",
          assd: "",
        }}
        renderItem={(item, index) => (
          <QqcMonthlyItem reportItem={item} index={index} key={item.id} onUpdate={qqcMonthly.request} onDelete={qqcMonthly.request} request={qqcMonthly.request} reportItems={reportItem.response ? reportItem.response.results : []} finance={item} />
        )}
      />
    </Layout>
  );
}
