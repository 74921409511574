import React from 'react'
import { StyleSheet } from 'aphrodite'
import { useHistory } from 'react-router-dom'
import { useGetRequest } from '../hooks/request'
import { useModal } from '../hooks/modal'
import { useMessage } from '../hooks/message'
import FinanceUpdate from './FinanceUpdate'
import { domain } from '../utils/request'
import Button from './componentsFinance/common/Button'
import FinanceInfo from './FinanceInfo'
import { FINANCE_ARCHIVE, MANTHLY_FINANCE_ARCHIVE } from '../urls'

export default function FinanceItem({ item, index, onDelete, onUpdate, page }) {
    const history = useHistory()
    const financeUpdate = useGetRequest({ url: item.types === 'zero_types' ? FINANCE_ARCHIVE.replace('{id}', item.id) : MANTHLY_FINANCE_ARCHIVE.replace('{id}', item.id) })
    const [showUpdateModal, hideUpdateModal] = useModal(
        <FinanceUpdate
            finance={item}
            onUpdate={() => {
                onUpdate()
                hideUpdateModal()
            }}
            onCancel={() => hideUpdateModal()}
        />,
        styles.modal,
    )

    const [showMessage] = useMessage()

    async function deleteFinance() {
        if (!global.confirm('Вы действительно хотите удалить?')) return

        const { error } = await financeUpdate.request()
        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }
        onDelete()
    }

    async function onSubmit() {
        const { success } = await financeUpdate.request()
        if (success) {
            onUpdate()
        }
    }

    const handleClick = () => {
        console.log(item)
        const url = item.types === 'monthly_types' ? `/clients/manthly/${item.id}` : item.types === 'zero_types' && `/clients/zero/${item.id}`

        history.push(url)
    }

    const [showViewModal, hideViewModal] = useModal(<FinanceInfo lead={item} onSuccess={() => hideViewModal()} onCancel={() => hideViewModal()} />)
    const itemsPerPage = 10
    const position = (page - 1) * itemsPerPage + index + 1

    return (
        <tr>
            <td className="has-text-center pr-3 pl-3">{position}</td>
            <td
                onClick={handleClick}
                style={{
                    cursor: 'pointer',
                    color: '#1d4ed8',
                    textDecoration: 'underline',
                    textUnderlineOffset: '3px',
                }}
                className="has-text-center pr-3 pl-3"
            >
                {item.name}
            </td>
            <td className="has-text-center pr-3 pl-3">{item.stir}</td>
            <td className="has-text-center pr-3 pl-3">{item.directorName}</td>
            <td className="has-text-center pr-3 pl-3">{item.directorStir}</td>
            <td className="pr-6">
                <a href={domain + item.first} className="has-text-dark has-text-center">
                    <Button icon="icon ion-md-download" className="is-pulled-right is-black is-outlined is-small" />
                </a>
            </td>
            <td className="pr-6">
                <a href={domain + item.second} className="has-text-dark">
                    <Button icon="icon ion-md-download" className="is-pulled-right is-black is-outlined is-small" />
                </a>
            </td>
            <td className="has-text-center pr-3 pl-3">
                <Button
                    icon="icon ion-md-trash"
                    onClick={onSubmit}
                    className="is-pulled-right is-danger is-outlined is-small"
                />

            </td>
            <td className="has-text-center pr-3 pl-3">
                <Button icon="icon ion-md-create" onClick={showUpdateModal}
                    className="is-pulled-right is-success is-outlined is-small" />
            </td>
        </tr>
    )
}

const styles = StyleSheet.create({ modal: { width: 800 } })
