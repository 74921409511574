import React from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import Progress from "./componentsFinance/common/Progress";
import { useDeleteRequest } from "../hooks/request";
import { MANTHLY_QQC_QUARTERLY_DETAIL } from "../urls";
import { useMessage } from "../hooks/message";
import { useModal } from "../hooks/modal";
import ManthlyQqcQuarterlyUpdate from "./ManthlyQqcQuarterlyUpdate";
import { getMonth } from "../utils/date";
import { checkPermission as cp } from "../utils/auth";
import Button from "./common/Button";

export default function ManthlyQqcQuarterlyItem({ reportItem, index, onUpdate, onDelete }) {
  const rotationDelete = useDeleteRequest({
    url: MANTHLY_QQC_QUARTERLY_DETAIL.replace("{id}", reportItem ? reportItem.id : ""),
  });
  const [showUpdateModal, hideUpdateModal] = useModal(
    <ManthlyQqcQuarterlyUpdate
      report={reportItem}
      onUpdate={() => {
        onUpdate();
        hideUpdateModal();
      }}
      onCancel={() => {
        hideUpdateModal();
      }}
    />,
  );

  const [showMessage] = useMessage();

  async function deleteReport() {
    if (!global.confirm("Вы действительно хотите удалить?")) return;

    const { error } = await rotationDelete.request();
    if (error) {
      showMessage(error.data.detail, "is-danger");
      return;
    }
    onDelete();
  }

  return (
    <tr className="">
      <td className="has-text-centered">{index + 1}</td>
      <td className={cn("mt-3 is-centered")}>
        <NavLink className="" to={`/manthly/qqc-quarterly-item/${reportItem.id}`}>
          <span className="tag is-info is-light is-medium">{getMonth(reportItem.date)}</span>
        </NavLink>
      </td>

      <td className={cn("has-text-centered")}>{reportItem.clientCount}</td>

      <td className={cn("has-text-centered")}>{reportItem.taskCount}</td>

      <td className="has-text-centered">{reportItem.taxCount}</td>
      <td className="has-text-centered">{reportItem.inpsCount}</td>
      <td className="has-text-centered">
        <span className="tag is-info is-light mb-2 is-medium">Ҳисоботлар жўнатилиши </span> <br />
        <span className="tag is-success is-light mb-2 is-medium">Солиқлар тўланиши </span> <br />
        {/* <span className="tag is-danger is-light mb-2 is-medium">ИНПС реестр жўнатилиши</span> <br /> */}
      </td>

      <td className="has-text-centered">
        <div style={{ width: 150 }} className="mt-2">
          <Progress value={reportItem.progress} text={`${reportItem.progress} %`} /> <br />
          <Progress value={reportItem.progressTax} text={`${reportItem.progressTax} %`} /> <br />
          {/* <Progress value={reportItem.progressInps} text={`${reportItem.progressInps} %`} /> <br /> */}
        </div>
      </td>

      {cp("staff.delete_employee") ? (
        <td className="has-text-centered">
          <Button className="is-outlined is-danger mb-2 is-small" icon="ion-md-trash" onClick={deleteReport} />

          <Button className="is-outlined is-success is-small" icon="ion-md-create" onClick={showUpdateModal} />
        </td>
      ) : (
        <td className="has-text-centered" />
      )}
    </tr>
  );
}
